<template>
  <div class="xcx_J">
        <div class="xcx_l">
            <div class="xcx_bg">
                
            </div>
            <img class="xcx_logo" src="../assets/img/jump_logo.png" alt="">
        </div>
        <div class="xcx_text">
            <p>请登录【长城文创珍宝中心】</p>
            <p>小程序进行账号授权…</p>
        </div>
        <div class="xcx_btn" @click="goXcx">
            <img src="../assets/img/xcx_link.png" alt=""> 长城文创珍宝中心
        </div>
        <div class="xcx_tips">点击进入微信小程序</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
          web_tk_code:''
        }
    },
    methods:{
        goXcx(){
             this.axios.post('auth/getUrlScheme',{page:'/pages/master/webLogin',data:'web_tk_code='+this.web_tk_code}).then((res)=>{
                let url = res.data.data.data.openlink
                window.location.href = url
              })
        },
        handleVisiable: function (e) {
          if (e.target.visibilityState == "visible") {
            this.axios.get('auth/getWebToken?web_tk_code='+ this.web_tk_code).then((res)=>{
                if(res.data.code == 0){
                  window.localStorage.setItem("user_token", res.data.data.token);
                  this.$router.back()
                }
            })
          } else {
            
          }
        },

    },
    mounted(){
      document.addEventListener("visibilitychange", this.handleVisiable);
      let date = new Date().getTime()
      this.web_tk_code = parseInt(Math.random()*50) + '' + date
    }
}
</script>

<style scoped>
.xcx_J{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #390200;
}
@keyframes rote {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.xcx_bg{
    width: 1.13rem;
    height: 1.08rem;
    background: url(../assets/img/jump_bg.png) no-repeat;
    background-size: 100% 100%;
    animation: rote 4s infinite ease-in-out ;
    animation-delay: 0.5s;
}
.xcx_l{
    position: relative;
    display: flex;
    flex-direction: column;
}
.xcx_logo{
    width: 0.44rem;
    height: 0.57rem;
    position: absolute;
    left: 0.345rem;
    top: 0.255rem;
}
.xcx_text{
    margin-top: 0.275rem;
    font-size: 0.16rem;
    color: #e9d9ae;
}
.xcx_btn{
  margin-top: 20vh;
  width: 2.2rem;
  height: 0.46rem;
  border-radius: 0.04rem;
  box-shadow: inset 0 0 0 0.01rem #e9d9ae;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.15rem;
  color: #e9d9ae;
}
.xcx_text p{
  text-align: center;
}
.xcx_btn img{
  width: 0.15rem;
  height: 0.15rem;
  margin-right: 0.07rem;
}
.xcx_tips{
  margin-top: 0.3rem;
   font-size: 0.15rem;
  color: #e9d9ae;
}
</style>